@use '../../../mb-styles/scss/mb-globals/variables';
@use 'node_modules/bootstrap/scss/mixins' as bootstrap;

.offcanvas {
  z-index: variables.$zindex-offcanvas;

  &.offcanvas-has-footer .offcanvas-body {
    height: calc(100vh - 134px);
  }
}

.offcanvas-backdrop {
  z-index: variables.$zindex-offcanvas-backdrop;
}

.mb-modal-container ~ .offcanvas-backdrop,
.mb-modal-container ~ .offcanvas,
.offcanvas ~ .mb-modal-backdrop {
  z-index: variables.$modalContainerZIndex;
}

.mb-overlay-container ~ .offcanvas-backdrop {
  z-index: variables.$zindex-offcanvas;
}

.offcanvas-header {
  background-color: variables.$blue;
  color: variables.$white;
  padding: 10px;

  .offcanvas-title {
    @include bootstrap.font-size(22px !important);
    font-weight: initial;
    margin-bottom: 0;
  }

  @include bootstrap.media-breakpoint-down(lg, variables.$grid-breakpoints) {
    .offcanvas-title {
      @include bootstrap.font-size(20px);
    }
  }

  button.close-button:hover {
    background-color: variables.$blue;
    border-color: variables.$blue;
  }
}

.offcanvas-body {
  height: calc(100vh - 56px);
  flex-grow: inherit;
  padding: 0;
}