/*!
 * https://github.com/arqex/react-datetime
 */

@use '../../../styles/mb-styles/scss/mb-globals/variables';
@use 'node_modules/bootstrap/scss/mixins' as bootstrap;

.rdt {
	position: relative;

	&.bottom-position .rdtPicker {
		bottom: 2rem;
	}

	&.right-position .rdtPicker {
		right: 0;
	}

	.rdtPicker {
		display: none;
		position: absolute;
		min-width: 250px;
		padding: 4px;
		margin-top: 1px;
		z-index: 99999 !important;
		background: variables.$white;
		box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
		border: 1px solid variables.$charcoal-200;



		.rdtTimeToggle {
			text-align: center;
		}

		table {
			width: 100%;
			margin: 0;

			td,
			th {
				text-align: center;
				height: 28px;
			}

			td {
				cursor: pointer;

				&.rdtDay {
					background: variables.$surface-dark;
					border: solid 1px variables.$white;

					&.scheduledDay {
						background-color: variables.$indigo-lighter !important;
					}
				}

				&.rdtOld,
				&.rdtNew {
					visibility: hidden;
				}

				&.rdtDay:hover,
				&.rdtHour:hover,
				&.rdtMinute:hover,
				&.rdtSecond:hover {
					background: variables.$surface-darker;
					cursor: pointer;
				}

				&.rdtToday {
					background: variables.$yellow-light-3 !important;
				}

				&.rdtActive,
				&.rdtActive:hover {
					background-color: variables.$blue-dark-2 !important;
					color: variables.$white !important;
					text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);

					&.scheduledDay {
						background-color: variables.$blue-dark-2 !important;
					}
				}

				&.rdtActive.rdtToday:before {
					border-bottom-color: variables.$white;
				}

				&.rdtDisabled,
				&.rdtDisabled:hover {
					background: none;
					color: variables.$charcoal-500;
					cursor: not-allowed;
				}

				span.rdtOld {
					color: variables.$charcoal-500;
				}

				span.rdtDisabled,
				span.rdtDisabled:hover {
					background: none;
					color: variables.$charcoal-500;
					cursor: not-allowed;
				}
			}

			th {
				border-bottom: 1px solid variables.$white;

				&.rdtSwitch {
					width: 100px;
				}

				&.rdtNext,
				&.rdtPrev {
					font-size: 21px;
					vertical-align: top;

					&:hover {
						background-color: variables.$blue-dark-3 !important;
					}

					span {
						display: block;
						-webkit-touch-callout: none;
						/* iOS Safari */
						-webkit-user-select: none;
						/* Chrome/Safari/Opera */
						-khtml-user-select: none;
						/* Konqueror */
						-moz-user-select: none;
						/* Firefox */
						-ms-user-select: none;
						/* Internet Explorer/Edge */
						user-select: none;
					}
				}

				&.rdtDisabled,
				&.rdtDisabled:hover {
					background: none;
					color: variables.$charcoal-500;
					cursor: not-allowed;
				}
			}

			.dow {
				width: 14.2857%;
				border-bottom: none;
				cursor: default;
			}

			thead {
				button {
					width: 100%;
					height: 100%;
				}

				tr:first-of-type th {
					cursor: pointer;
					background-color: variables.$blue-dark-2 !important;
					color: variables.$white !important;
				}
			}

			tfoot {
				border-top: 1px solid variables.$white;
			}

		}

		.rdtTimeToggle:hover {
			background: variables.$surface-darker;
			cursor: pointer;
		}
	}

	button:not(.btn) {
		border: none;
		background: none;
		cursor: pointer;

		&:hover {
			background-color: variables.$surface-darker;
		}
	}

	td.rdtMonth,
	td.rdtYear {
		height: 50px;
		width: 25%;
		cursor: pointer;

		&:hover {
			background: variables.$surface-darker;
		}
	}

	.rdtCounters {
		display: inline-block;

		> div {
			float: left;
		}
	}

	.rdtCounter {
		height: 100px;
		width: 40px;

		.rdtBtn {
			height: 40%;
			line-height: 40px;
			cursor: pointer;
			display: block;
		
			-webkit-touch-callout: none;
			/* iOS Safari */
			-webkit-user-select: none;
			/* Chrome/Safari/Opera */
			-khtml-user-select: none;
			/* Konqueror */
			-moz-user-select: none;
			/* Firefox */
			-ms-user-select: none;
			/* Internet Explorer/Edge */
			user-select: none;

			&:hover {
				background: variables.$surface-darker;
			}

			.rdtCount {
				height: 20%;
				font-size: 1.2em;
			}
		}
	}

	.rdtCounterSeparator {
		line-height: 100px;
	}

	.rdtMilli {
		vertical-align: middle;
		padding-left: 8px;
		width: 48px;

		input {
			width: 100%;
			font-size: 1.2em;
			margin-top: 37px;
		}
	}

	.rdtTime td {
		cursor: default;
	}

	&.rdtOpen .rdtPicker {
		display: block;
	}

	&.rdtStatic .rdtPicker {
		box-shadow: none;
		position: static;
	}
}