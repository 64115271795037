@use '../../../mb-styles/scss/mb-globals/variables';

.mb-popover-container.popover {
    z-index: variables.$zindex-offcanvas + 1;

    .popover-arrow {
        transform: initial !important;
        left: initial !important;
        right: 15px;
    }
}