.alert-dismissible {
    display: flex;
    align-items: center;
    padding-right: 1rem !important; 

    .btn-close {
        order: 1;
        margin-left: auto;
        position: inherit;
        padding: 0;
    }
}